<template>
    <div class="row">
        <div class="col-12 col-md-4">
            <dashboard-total-items :item="totalNew" variant="primary">{{ $t('DASHBOARD.TOTALS.NEW') }}</dashboard-total-items>
        </div>
        <div class="col-12 col-md-4">
            <dashboard-total-items :item="totalCompleted" variant="success">{{ $t('DASHBOARD.TOTALS.SOLVED') }}</dashboard-total-items>
        </div>
        <div class="col-12 col-md-4">
            <dashboard-total-items :item="totalProcessing" variant="warning">{{ $t('DASHBOARD.TOTALS.IN_OPERATION') }}</dashboard-total-items>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DashboardTotalItems from './DashboardTotalsItem.vue';

export default {
    name: "DashboardTotals",
    components: {
        DashboardTotalItems,
    },
    computed: {
        ...mapGetters({
            totalApplications: 'dashboard/totalApplications',
        }),
        totalNew() {
            return this.totalApplications?.new;
        },
        totalCompleted() {
            return this.totalApplications?.completed;
        },
        totalProcessing() {
            return this.totalApplications?.processing;
        },
    },
}

</script>
