<template>
  <div>
    <b-card class="st-section">
      <div class="st-section-body">
        <div>
          <p class="font-size-lg font-weight-bold">
            {{ $t('DASHBOARD.TYPES.APPLICATIONS_PER_UATS') }}
          </p>
          <div class="mt-6">
            <div class="row uat-list-container">
              <div class="col col-6 uat-list-item" v-for="uat in applicationsPerUat" :key="uat.territorial_administrative_unit_id">
                <div class="row">
                  <div class="col-6">
                    <span>{{ uat.territorial_administrative_unit_name }}</span>
                  </div>
                  <div class="col-2 text-right">
                    <span class="text-primary">{{ uat.new }}</span>
                  </div>
                  <div class="col-2 text-right">
                    <span class="text-success">{{ uat.completed }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'DashboardPerUat',
  components: {
  },
  computed: {
    ...mapGetters({
      applicationsPerUat: 'dashboard/applicationsPerUat',
    }),
  },
}

</script>

<style lang="scss" scoped>
  .uat-list-container {
    height: 430px;
    overflow-y: scroll;
    overflow-x: hidden;
    .uat-list-item {
      padding: 1rem 1rem 0 2.5rem;
    }
  }
</style>