<template>
    <div>
        <st-filters-pagination
            stateModule="dashboard"
            :filters="filters"
            @search="doFilter"
            :clearButtonInPrimaryFilters="true"
            :showPagination="false"
            :moreFilters="false"
            :showErase="false"
            :currentPage="1"
            :emitFilters="true"
        >
            <slot></slot>
        </st-filters-pagination>
    </div>
</template>

<script>
    import { mapActions } from 'vuex';
    import { FilterSchema } from '@/shared/form/filter-schema';
    import { DashboardModel } from '@/modules/dashboard/dashboard-model';

    const { fields } = DashboardModel;

    export default {
        name: 'DashboardToolbar',
        data() {
            return {
                fields
            }
        },
        computed: {
            filters() {
                const filterSchema = new FilterSchema([
                    this.fields.start_date, 
                ]);
                return filterSchema.fields;
            }
        },
        methods: {
            ...mapActions({
                fetchData: 'dashboard/getDashboardReports'
            }),
            async doFilter(params) {
                const types = 'total_applications,total_applications_by_type,applications_avg_resolution_goal,applications_performance,applications_by_month,applications_per_uat';
                const payload = {
                    dashboard_types: types
                }

                const filters = params.filter(filter => {
                    if (filter.type === 'daterange') {
                        return filter.value;
                    }
                }).map((el) => el.value);

                if (filters.length) {
                    filters.forEach((el) => {
                        if (el?.start) {
                            payload.start_date = el.start;
                        }

                        if (el?.end) {
                            payload.end_date = el.end;
                        }
                    })
                }

                await this.fetchData(payload);
            },
        },
    };
</script>
