<template>
    <div class="row" v-if="totalApplicationsByType">
        <div class="col-12 col-xl-6 mt-6" v-for="item in totalApplicationsByType" :key="item.id">
            <b-card class="st-section">
                <div class="st-section-header">
                     <p class="font-size-h5 font-weight-bold mb-0">
                        {{ item.application_type_name }}
                    </p>
                </div>
                <div class="st-section-body">
                    <div class="row">
                        <div class="col col-5">
                            <div class="stats-header mb-6"><i class="fas fa-signal text-primary mr-2"></i>{{ $t('DASHBOARD.TYPES.STATS') }}</div>
                            <div class="d-flex justify-content-between mb-2">
                                <div><i class="fas fa-dot-circle text-primary mr-2"></i>{{ $t('DASHBOARD.TYPES.NEW') }}</div>
                                <div>{{ item.new }}</div>
                            </div>
                            <div class="d-flex justify-content-between mb-2">
                                <div><i class="fas fa-dot-circle text-success mr-2"></i>{{ $t('DASHBOARD.TYPES.SOLVED') }}</div>
                                <div>{{ item.completed }}</div>
                            </div>
                            <div class="d-flex justify-content-between mb-2">
                                <div><i class="fas fa-dot-circle text-warning mr-2"></i>{{ $t('DASHBOARD.TYPES.IN_OPERATION') }}</div>
                                <div>{{ item.processing }}</div>
                            </div>
                        </div>
                        <div class="col col-4 type-chart">
                            <apexchart type="donut" width="160" height="135" :options="optionsTerm" :series="[item.new, item.completed, item.processing]"></apexchart>
                        </div>
                        <div class="col col-3">
                            <div class="d-flex align-items-center justify-content-end">
                                <span class="text-uppercase mr-4">{{ $t('DASHBOARD.TYPES.TOTAL_LABEL') }}</span>
                                <span class="total-nr">{{ item.total }}</span>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="d-flex justify-content-between align-items-center">
                        <div class="stats-header">
                            <i class="far fa-clock text-primary mr-2"></i>
                            <span>{{ $t('DASHBOARD.TYPES.SOLUTION_TERM') }}</span>
                        </div>
                        <div class="d-flex align-items-center justify-content-end">
                            <span class="text-uppercase mr-4">{{ $t('DASHBOARD.TYPES.DAYS') }}</span>
                            <span class="total-days">{{ item.resolution_avg }}</span>
                        </div>
                    </div>
                    <template v-if="item.uats && item.uats.length">
                        <hr>
                        <div class="mt-6">
                            <div class="stats-header mb-6"><i class="fas fa-map-marker-alt text-primary mr-2"></i>{{ $t('DASHBOARD.TYPES.APPLICATIONS_PER_UATS') }}</div>
                            <div class="row uat-list-container">
                                <div class="col col-6 uat-list-item" v-for="uat in item.uats" :key="uat.territorial_administrative_unit_id">
                                    <div class="row">
                                        <div class="col-6">
                                            <span>{{ uat.territorial_administrative_unit_name }}</span>
                                        </div>
                                        <div class="col-2 text-right">
                                            <span class="text-primary">{{ uat.new }}</span>
                                        </div>
                                        <div class="col-2 text-right">
                                            <span class="text-warning">{{ uat.processing }}</span>
                                        </div>
                                        <div class="col-2 text-right">
                                            <span class="text-success">{{ uat.completed }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </b-card>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import VueApexCharts from 'vue-apexcharts'

export default {
    name: 'DashboardSummaryByType',
    components: {
        apexchart: VueApexCharts,
    },
    data() {
        return {
            optionsTerm: {
                labels: [ this.$t('DASHBOARD.TYPES.NEW'), this.$t('DASHBOARD.TYPES.SOLVED'), this.$t('DASHBOARD.TYPES.IN_OPERATION')],
                colors:['#286AE4', '#0BB783', '#FFA800'],
                dataLabels: {
                    enabled: false,
                    position: 'bottom'
                },
                legend: {
                    show: false,
                },
            },
        };
    },
    computed: {
        ...mapGetters({
            totalApplicationsByType: 'dashboard/totalApplicationsByType',
        }),
    },
    methods: {

    },
}
</script>

<style lang="scss" scoped>
    .stats-header {
        display: flex;
        align-items: center;
        color: #6C6C6C;
        text-transform: uppercase;
    }
    .total-nr {
        color: #286AE4;
        font-size: 2rem;
        background-color: #E0EAFC;
        padding: 0 1rem;
    }
    .total-days {
        color: #F4B026;
        background-color: rgba(244,176,38,0.2);
        font-size: 2rem;
        padding: 0 1rem;
    }
    .uat-list-container {
        height: 100px;
        overflow-y: scroll;
        overflow-x: hidden;
        .uat-list-item {
            padding: 1rem 1rem 0 2.5rem;
        }
    }
    .type-chart {
        height: 112px; // Fix for unexpected min-height on chart calculated dynamically
    }
</style>
