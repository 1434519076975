<template>
  <div>
    <b-card class="st-section">
      <div class="st-section-body">
        <div>
          <p class="font-size-lg font-weight-bold">
            {{ $t('DASHBOARD.REGISTERED_APPLICATIONS.HEADER') }}
          </p>
          <p class="font-size-sm mb-10">
            {{ $t('DASHBOARD.REGISTERED_APPLICATIONS.DESCRIPTION') }}
          </p>
          <div class="row">
              <div class="col-2 font-size-lg font-weight-bold">
                <i class="fas fa-dot-circle text-primary mr-2"></i>{{ $t('DASHBOARD.TYPES.NEW') }}
              </div>
              <div class="col font-size-lg font-weight-bold">
                <i class="fas fa-dot-circle text-success mr-2"></i>{{ $t('DASHBOARD.TYPES.SOLVED') }}
              </div>
          </div>
          <div id="chart">
            <apexchart height="350" :options="optionsComputed" :series="seriesComputed"></apexchart>
          </div>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';
import { mapGetters } from 'vuex';

export default {
  name: 'DashboardPerformance',
  components: {
    apexchart: VueApexCharts,
  },
  computed: {
    ...mapGetters({
      applicationsByMonth: 'dashboard/applicationsByMonth',
    }),
    seriesNew() {
      return this.applicationsByMonth?.map(item => item.new * (-1)) || [];
    },
    seriesCompleted() {
      return this.applicationsByMonth?.map(item => item.completed) || [];
    },
    months() {
      return this.applicationsByMonth?.map(item => item.month) || [];
    },
    seriesComputed() {
      return [
        {
          name: this.$t('DASHBOARD.TYPES.NEW'),
          data: this.seriesNew,
          color: '#286AE4'
        }, {
          name: this.$t('DASHBOARD.TYPES.SOLVED'),
          data: this.seriesCompleted,
          color: '#0BB783'
        }
      ]
    },
    optionsComputed() {
      return {
        chart: {
          type: 'bar',
          height: '350',
          width: '100%',
          stacked: true,
          toolbar: {
            show: true
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '30%',
            endingShape: 'rounded',
          },
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          categories: this.months,
          type: 'datetime',
          labels: {
          datetimeFormatter: {
              year: 'yyyy',
              month: 'MM.yyyy',
              day: 'dd',
            }
          }
        },
        yaxis: {
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          x: {
            show: false
          },
          y: {
            formatter: function (val) {
              return Math.abs(val)
            }
          }
        },
        legend: {
          show: false
        }
      }
    }
  },
}

</script>
