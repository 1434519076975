import GenericModel from '@/shared/models/generic-model';
import i18n from '@/shared/plugins/vue-i18n';
import DateField from '@/shared/fields/date-field';

const label = (name) => i18n.t(`DASHBOARD.FIELDS.${name.toUpperCase()}`);

const fields = {
    start_date: new DateField('start_date', label('start_date'),{},{ primary: true }),
    end_date: new DateField('end_date', label('end_date'),{},{ primary: true }),
};

export class DashboardModel extends GenericModel {
    static get fields() {
        return fields;
    }
}
