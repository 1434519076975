<template>
    <div>
        <Loader v-if="loading['dashboards/getDashboardReports']"></Loader>
        <dashboard-toolbar class="mb-10 mt-10"></dashboard-toolbar>

        <div v-if="data">
            <div class="row mb-6">
                <div class="col-12 col-xl-6">
                    <dashboard-new-closed-chart />
                </div>
                <div class="col-12 col-xl-6">
                    <dashboard-per-uat />
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-xl-3">
                    <dashboard-performance />
                </div>
                <div class="col-12 col-xl-9">
                    <dashboard-totals/>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <dashboard-summary-by-type />
                </div>
                <div class="col-12 mt-6">
                    <dashboard-recent-applications />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DashboardPerformance from '../components/DashboardPerformance';
import DashboardRecentApplications from '../components/recent-applications/DashboardRecentApplications';
import DashboardTotals from '../components/DashboardTotals';
import DashboardSummaryByType from '../components/DashboardSummaryByType';
import DashboardToolbar from '../components/DashboardToolbar.vue'
import DashboardNewClosedChart from '../components/DashboardNewClosedChart.vue'
import DashboardPerUat from '../components/DashboardPerUat.vue'

const dashboardModule = () => import('@/modules/dashboard/dashboard-store');
const applicationsModule = () => import('@/modules/applications/applications-store');

export default {
    name: 'DashboardReports',
    components: {
        DashboardRecentApplications,
        DashboardPerformance,
        DashboardTotals,
        DashboardSummaryByType,
        DashboardNewClosedChart,
        DashboardPerUat,
        DashboardToolbar,
    },
    computed: {
        ...mapGetters({
            data: 'dashboard/data',
            loading: 'shared/loading',
        })
    },
}
</script>
