<template>
    <b-card class="st-section">
        <div class="st-section-body">
            <div class="row">
                <div class="col">
                    <p class="font-size-lg font-weight-bold mb-0">
                        {{ $t('DASHBOARD.PERFORMANCE.HEADER') }}
                    </p>
                    
                    <div id="chart">
                        <apexchart width="280" :options="chartOptions" :series="series"></apexchart>
                    </div> 
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <p class="font-size-lg mb-0">
                        {{ $t('DASHBOARD.PERFORMANCE.OBJECTIVE_HEADER') }}: <strong> {{ objective }} </strong>
                    </p>
                </div>
            </div>
        </div>
    </b-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';
import { mapGetters } from 'vuex';

export default {
    name: 'DashboardPerformance',
    components: {
        apexchart: VueApexCharts,
    },
    data() {
        return {
            chartOptions: {
                chart: {
                    type: 'donut',
                },
                dataLabels: {
                    enabled: false,
                    enabledOnSeries: null
                },
                plotOptions: {
                    pie: {
                        startAngle: 0,
                        expandOnClick: true,
                        offsetX: -70,
                        offsetY: 0,
                        customScale: 0.75,
                        donut: {
                            size: '85%',
                            background: 'transparent',
                            labels: {
                                show: true,
                                total: {
                                    show: true,
                                    showAlways: false,
                                    label: this.$t('DASHBOARD.PERFORMANCE.RESOLUTION_AVG'),
                                    fontSize: '18px',
                                    fontWeight: 600,
                                    color: 'black',
                                    formatter: () => {
                                        const avg = this.applicationsPerformance?.resolution_avg;
                                        return avg ? `${avg} ${this.$tc('GENERAL.DAYS', avg)}` : '';
                                    }
                                }
                            }
                        }
                    }
                },
                colors: ['#F64E60', '#4AB58E', '#FFA801'],
                labels: [this.$t('DASHBOARD.PERFORMANCE.ABOVE_AVG'), this.$t('DASHBOARD.PERFORMANCE.NORMAL'), this.$t('DASHBOARD.PERFORMANCE.BELLOW_AVG')],
                legend: {
                    show: true,
                    floating: true,
                    fontSize: '10px',
                    position: 'right',
                    offsetX: 20,
                    offsetY: -10,
                    labels: {
                        useSeriesColors: true,
                    },
                    markers: {
                        size: 0
                    },
                    formatter: function(seriesName, opts) {
                        return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex]
                    },
                    itemMargin: {
                        vertical: 0
                    }
                },
            },
        }
    },
    computed: {
        ...mapGetters({
            applicationsPerformance: 'dashboard/applicationsPerformance',
            applicationsResolutionTermObjective: 'dashboard/applicationsResolutionTermObjective',
        }),
        series() {
            return [this.applicationsPerformance?.grater_than_avg_count ?? 0, this.applicationsPerformance?.is_avg_count ?? 0, this.applicationsPerformance?.lower_than_avg_count ?? 0];
        },
        objective() {
            const value = this.applicationsResolutionTermObjective ?? 0; 
            return `${value} ${this.$tc('GENERAL.DAYS', value)}`
        }
    }
}

</script>
