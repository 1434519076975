<template>
    <b-card class="st-section">
        <div class="st-section-header">
            <div class="d-flex justify-content-between w-100">
                <p class="font-size-lg font-weight-bold mb-0">
                    <slot></slot>
                </p>
                <h2 :class="`text-${variant}`">{{value}}</h2>
            </div>
        </div>
        <div class="st-section-body">
            <div class="row">
                <div class="col-12"> <p>{{ $t('DASHBOARD.TOTALS.PERCENT') }}</p> </div>
                <div class="col-12">
                    <b-progress max="100" height="1.8rem" :variant="variant">
                        <b-progress-bar :value="percent" :label="`${percent}%`">
                        </b-progress-bar>
                    </b-progress>
                </div>
            </div>
            <div v-if="topUsers.length" class="row mt-6">
                <div class="col-12"> <p>{{ $t('DASHBOARD.TOTALS.TOP_USERS') }}</p> </div>
                <div class="col-12">
                    <b-avatar-group size="3rem">
                        <b-avatar v-for="user in topUsers" :key="user._id" :text="userInitials(user.user_full_name)" :variant="randomUserVariant()" :title="user.user_full_name"></b-avatar>
                    </b-avatar-group>
                </div>
            </div>
        </div>
    </b-card>
</template>
<script>
import { BProgress, BProgressBar } from 'bootstrap-vue';

export default {
    name: 'DashboardTotalsItem',
    components: {
        BProgress,
        BProgressBar,
    },
    props: {
        item: {
            type: Object,
            default: () => ({
                nr: 0,
                percent: 0
            })
        },
        variant: {
            type: String,
            default: 'primary',
        },
    },
    data() {
        return {
            total: 100,
            variants: [ 'success', 'warning', 'danger', 'info', 'primary', 'secondary', 'dark']
        }
    },
    computed: {
        value() {
            return this.item?.nr ?? 0;
        },
        percent() {
            return this.item?.percent ?? 0;
        },
        topUsers() {
            return this.item?.top ?? [];
        }
    },
    methods: {
        userInitials(fullname) {
            const name = fullname.split('');
            const firstName = name[0];
            const lastName = name[1];
            return `${firstName[0]}${lastName[0]}`;
        },
        randomUserVariant() {
            return this.variants[Math.floor(Math.random() * this.variants.length)];
        }
    }
}
</script>

<style lang="scss" scoped>
.b-avatar-group {
    .b-avatar {
        &:hover {
            cursor: pointer;
            z-index: 1;
            transition: all .3s ease;
            z-index: 1;
        }
    }
}
</style>
